export function throttle(callbackFn, limit) {
  let wait = false;

  return function () {
    if (!wait) {
      callbackFn.call();
      wait = true;

      setTimeout(function() {
        wait = false;
      }, limit);
    }
  };
}